.a-userInfo {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 220px; // NOTE: Due to alignment with the cover image

    &__header {
        .a-iconBtn {
            height: auto;
        }
    }

    &__mainInfo {
        display: flex;
        flex-direction: column;
        position: relative;

        &--image,
        &--placeholder {
            background-color: $dark-blue-20;
            outline: 0.5px solid $dark-blue-20;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 140px !important;
            min-width: 140px;
            max-width: 140px;
            height: 0;
            padding-top: 140px;
            border-radius: 100%;

            &.a-userInfo__mainInfo--image {
                cursor: pointer;
            }

            .-edit {
                position: absolute;
                top: unset;
                bottom: 5px;
                right: 5px;
                background: none;
                outline: inherit;
                border: none;
                padding: 0;
                cursor: pointer;
                transition: 0.3s ease-in-out;
            }

            .-placeholderImage {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .-status {
            z-index: 2;
            position: absolute;
            top: 0;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center bottom;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 140px;
            min-width: 140px;
            max-width: 140px;
            height: 0;
            padding-top: 140px;
            border-radius: 100%;

            &.-available {
                background-image: url('../../../images/available.png');
            }

            &.-unavailable {
                background-image: url('../../../images/unavailable.png');
            }

            &.-employed {
                background-image: url('../../../images/employed.png');
            }

            &.-freelancer {
                background-image: url('../../../images/freelancer.png');
            }
        }

        .a-userInfo__mainInfo--image + .-status {
            cursor: pointer;
        }

        &--image {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: $white;
            transition: 0.3s ease-in-out;
        }
    }

    .a-progressBar {
        align-items: flex-start;
        padding-left: 0;
        padding-right: 0;

        .-bar {
            margin: 5px 0px 0px;
        }
    }
}
