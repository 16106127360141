.subscription-page {
    .subscription-type {
        padding: 5px 45px 5px 10px;
        border-radius: 5px;
        margin: 0 5px;
        gap: 5px;
    }
    .card-element-wrapper {
        border: 1px solid $dark-blue-10;
        padding: 14px 25px 15px 20px;
        border-radius: 4px;
        margin-top: 5px;
        transition: 0.3s ease-in-out;

        &:has(.StripeElement--focus) {
            border: 1px solid $light-blue;
        }
    }

    .plan-description-wrapper {
        display: flex;
        &.payment-type-selection {
            flex-direction: column;
            .-radioGroupElements {
                margin-top: 10px;
                padding-left: 0;
                border-radius: 10px;
                border: 1px solid $dark-blue-20;
                padding-right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                &::after {
                    display: none;
                }
                &.-checkedItem {
                    border: 1px solid $light-blue;
                    background-color: $light-blue-05;
                }
                label {
                    width: 152px;
                    height: 64px;
                    display: flex;
                    justify-content: center;
                    align-items: center !important;

                    div {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .m-radioGroupContainer__options--descriptionAttribute {
                            margin: 0;
                            font-size: 14px;
                            font-weight: 600;
                            color: $dark-blue;
                        }
                        span {
                            display: flex;
                            margin-right: 10px;
                        }
                    }
                }
                span {
                    &.radio {
                        display: none;
                    }
                }
            }
        }
        .m-radioGroupContainer {
            display: flex;
            gap: 10px;
            margin-bottom: 0;
            label {
                margin-bottom: 0;
            }
            .m-radioGroupContainer__options {
                margin-top: 0;
            }
        }
    }
    .payment-buttons {
        width: 100%;
    }
}

.billing-listing {
    .m-table {
        width: 100%;

        .plan-wrapper {
            background-color: $light-blue-05;
            padding: 5px;
        }
    }
    .plan-description-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
    }
}

.billing-modal-list {
    li {
        display: list-item;
        list-style: initial;
    }
}
