.m-helpAndInfo {
    .-diveRecordStatusesFlow {
        img {
            max-width: 400px;

            @include media-down(xs) {
                max-width: 300px;
            }
        }
    }
}
