.m-selectGroup {
    position: relative;
    margin-bottom: 10px;
    outline: 0;

    .clear {
        svg {
            position: absolute;
            right: 40px;
            transform: translateY(-50%);
            transition: 0.3s ease-in-out;
            overflow: visible;
            top: 27px;
            cursor: pointer;
            z-index: 4;
        }
    }

    input {
        padding: 0 60px 0 20px;

        &::placeholder {
            color: $dark-blue-60 !important;
        }
    }

    label {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &.-required {
        input {
            padding-right: 40px;
        }
    }

    &.-disabled {
        opacity: 0.7;

        .removeItemIcon,
        .m-selectGroup__arrow,
        svg {
            cursor: default;
        }
    }

    &.-custom {
        z-index: 2;
        position: relative;
        width: 100%;
    }

    &__container {
        position: relative;
        margin-top: 5px;

        &.-open {
            .a-input {
                border-radius: 4px 4px 0 0;
            }

            &:has(.-dropup) {
                .a-input {
                    border-radius: 0 0 4px 4px;
                }
            }
        }
    }

    &__select {
        width: 100%;

        &.-open {
            outline: 0;
            padding-left: 15px;
            border-radius: 4px 4px 0 0;

            &:focus {
                border-color: $light-blue;
            }

            ~ .a-input {
                padding-left: 15px;
                border-radius: 4px 4px 0 0;

                &:focus {
                    border-color: $light-blue;
                }
            }

            ~ .m-selectGroup__arrow {
                transform: translateY(-50%) rotate(225deg);
            }

            ~ .a-flag {
                display: none;
            }
        }

        &.-hide {
            color: transparent;

            &::placeholder {
                visibility: hidden;
                opacity: 0;
            }
        }

        &.-error {
            border-color: $red;
        }
    }

    &__options {
        opacity: 0;
        z-index: 2;
        position: absolute;
        top: 34px;
        width: 100%;
        min-height: fit-content;
        max-height: 150px;
        border: 1px solid $dark-blue-20;
        border-radius: 4px;
        background-color: white;
        pointer-events: none;
        transition: 0.2s ease-in-out;
        overflow: hidden;
        overflow-y: auto;
        @include scrollbar;

        &.-open {
            opacity: 1;
            top: 100%;
            pointer-events: initial;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                padding: 5px 10px;
                font-size: 13px;
                cursor: pointer;
                transition: 0.2s ease-in-out;

                &:hover {
                    background-color: $dark-blue-05;
                }
            }
        }
    }

    &__arrow {
        position: absolute;
        top: 50%;
        right: 20px;
        width: 8px;
        height: 8px;
        border-color: $dark-blue-60;
        border-style: solid;
        border-width: 0 1px 1px 0;
        cursor: pointer;
        transform: translateY(-50%) rotate(45deg);
        transition: 0.2s ease-in-out;
    }

    &__searchInput {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        outline: 0;
        border: 1px solid transparent;
        background-color: transparent;
        white-space: nowrap;
        padding-right: 40px;
    }

    &__loader {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 10px 0;

        &:hover {
            background-color: $white !important;
            cursor: default;
        }

        .arrow {
            display: flex;
            width: 30px;
            height: 15px;
            transform: rotate(90deg);
        }

        .arrow:before {
            content: '';
            flex: 1;
            margin: 0 5px;
            background: $dark-blue-20;
            clip-path: polygon(
                0% 40%,
                60% 40%,
                60% 0%,
                100% 50%,
                60% 100%,
                60% 60%,
                0% 60%
            );
            animation: a3 0.5s infinite alternate;
        }

        @keyframes a3 {
            0% {
                transform: scaleX(var(--s, 1)) translate(-4px);
            }

            100% {
                transform: scaleX(var(--s, 1)) translate(4px);
            }
        }
    }

    &__title {
        display: flex;
        flex-direction: column;
    }

    &.-multiple {
        .m-multiselectGroup__items {
            position: relative;
        }
    }

    &.-overflowVisible label {
        overflow: visible;
    }
}

.a-selectFromToGroup {
    width: 100%;

    .m-selectGroup {
        display: flex;
        flex-wrap: wrap;
    }

    .m-selectGroup__field {
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        gap: 10px;

        @include media-down(l) {
            flex-wrap: wrap;
        }

        & > div:first-of-type {
            width: calc(50% - 10px);

            @include media-down(l) {
                width: 100%;
            }
        }

        & > div:not(:first-of-type) {
            width: 25%;

            @include media-down(l) {
                width: calc(50% - 8px);
            }
        }

        .m-selectGroup {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
        }

        .a-btn {
            margin-top: 26px;
            margin-bottom: 0;

            @include media-down(l) {
                margin-top: 10px;
                width: 100%;
            }
        }
    }
}
