.m-avatar {
    position: relative;
    display: flex;
    align-items: center;
    width: max-content;
    max-width: 200px;
    border-radius: 30px;
    background-color: $dark-blue-05;
    padding: 5px 15px 5px 5px;
    cursor: pointer;

    @include media-down(l) {
        display: none;
    }

    &.-admin {
        @include media-down(xl) {
            display: none;
        }
    }

    &__imgInfo {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        user-select: none;

        img {
            width: 100%;
            height: 100%;
            background-color: $white;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $dark-blue-20;
    }

    &__info {
        display: flex;
        flex-direction: column;
        margin: 0 17px 0 8px;
        color: $dark-blue-20;
        max-width: fit-content;
        overflow: hidden;
    }

    &__name {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        color: $dark-blue;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        white-space: nowrap;
        max-width: 115px;
        width: max-content;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media-down(m) {
            width: 100%;
            max-width: max-content;
        }

        .-name {
            max-width: 115px;
            width: max-content;
            overflow: hidden;
            text-overflow: ellipsis;

            @include media-down(m) {
                max-width: max-content;
                width: 100%;
            }
        }

        .-lastName {
            margin-left: 0;
        }

        @include media-down(xxl) {
            .-lastName {
                display: none;
            }
        }

        @include media-down(l) {
            .-name {
                display: none;
            }
        }

        @include media-up(xxl) {
            flex-direction: row;
            flex-wrap: nowrap;

            .-lastName {
                margin-left: 5px;
            }
        }

        @include media-down(s) {
            flex-direction: row;
            flex-wrap: nowrap;

            .-lastName {
                margin-left: 5px;
            }
        }
    }

    &__popup {
        z-index: 3;
        opacity: 0;
        position: absolute;
        top: 45px;
        right: 0;
        display: flex;
        flex-direction: column;
        width: max-content;
        min-width: 200px;
        transform: translateY(-10px);
        border: 1px solid rgba($dark-blue, 0.2);
        border-radius: 10px;
        background-color: $white;
        padding: 0;
        box-sizing: border-box;
        pointer-events: none;
        transition: 0.3s ease-in-out;

        &.-open {
            transform: translateY(0);
            opacity: 1;
            pointer-events: initial;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        color: $dark-blue;
        cursor: pointer;

        svg {
            margin-right: 10px;
            transition: 0.3s ease-in-out;
        }

        &:hover {
            background-color: $dark-blue-05;
        }
    }
}
