//NOTE: File is intended for classes that handle colors.

.a-lightText {
    color: $dark-blue-80;

    &.-opacity-60 {
        color: $dark-blue-60;
    }

    &.-opacity-40 {
        color: $dark-blue-40;
    }
}

.a-whiteText {
    color: $white;

    &.-withLink:hover {
        color: $dark-blue-20;
    }
}

.a-blueLink {
    color: $light-blue;
    cursor: pointer;
}

.-green {
    border-color: $green;
    color: $green;
}

.-red {
    border-color: $red;
    color: $red;
}

.-redText {
    color: $red;
}

.-orangeText {
    color: $orange;
}

.-purpleText {
    color: $purple;
}

.-blueText {
    color: $light-blue;
}

.-greenText {
    color: $green;
}

.-orange {
    border-color: $orange;
    color: $orange;
}

.-purple {
    border-color: $purple;
    color: $purple;
}

.-blue {
    border-color: $light-blue;
    color: $light-blue;
}

.-gray {
    border-color: $dark-blue;
    color: $dark-blue;
}

.-pink {
    border-color: $pink;
    color: $pink;
}

.-yellow {
    border-color: $yellow;
    color: $yellow;
}

.-darkYellow {
    border-color: $dark-yellow;
    color: $dark-yellow;
}

.-grayDark {
    border-color: $dark-gray;
    color: $dark-gray;
}

.-grayLight {
    border-color: $dark-blue-20;
    color: $dark-blue;
}

.-orangeWithBcg {
    background-color: $orange-10;
    color: $orange;
    border: none;
}

.-purpleWithBcg {
    background-color: $purple-10;
    color: $purple;
    border: none;
}

.-blueWithBcg {
    background-color: $light-blue-10;
    color: $light-blue;
    border: none;
}

.-greenWithBcg {
    background-color: $green-10;
    color: $green;
    border: none;
}

.-silverWithBcg {
    background-color: $silver;
    color: $dark-gray;
    border: none;
}

.-grayWithBcg {
    background-color: $dark-blue-05;
    color: $dark-blue;
    border: none;

    &.-clients {
        padding: 5px 10px;
    }
}

.-silver {
    background-color: $silver;
    color: $dark-blue;
}

.defaultBcg {
    background-color: $dark-blue;
    background: url(../../../images/noAuth.png);
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
}

.bg-white {
    background-color: $white;
}

.bg-darkBlue02 {
    background-color: $dark-blue-02;
}

.bcg-lightBlue05 {
    background-color: #f2f8fb;
}

.bcg-darkBlue02 {
    background-color: $dark-blue-02;
}

.bcg-primaryBlue {
    background-color: $light-blue;
}

.a-approved {
    color: $green;
}

.a-declined {
    color: $red;
}

.a-link {
    color: $dark-blue;
    transition: 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
        color: $dark-blue-60;
    }
}

.a-darkBlueText {
    color: $dark-blue !important;
}

.a-darkGrayText {
    color: $dark-gray;
}
