.m-adciContainer {
    min-height: calc(100vh - 200px);

    &__wrapper {
        min-height: 450px;

        @include media-down(m) {
            margin-top: 50px !important;
        }
    }

    .form-wrapper,
    .result-wrapper {
        width: 80%;

        @include media-down(m) {
            width: 100%;
        }
    }

    .certificate-img {
        width: 300px;
    }

    .uploaded-certificate-wrapper {
        padding: 5px 10px;
        border: 1px solid $dark-blue-20;
        border-radius: 4px;

        .uploaded-certificate-img {
            width: 40px;
            height: 40px;

            .-image {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        span {
            &.reset-icon {
                cursor: pointer;
            }
        }
    }

    .compare-res {
        width: 80%;

        .compare-item-wrapper {
            display: flex;
            width: 100%;
            justify-content: center;

            .compare-item {
                padding: 10px 10px;

                .icon-wrapper {
                    width: 16px;
                    height: 16px;

                    .loading-icon {
                        svg {
                            animation: rotate 3s linear infinite;
                        }
                    }
                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }

                &.not-match {
                    color: $red;
                }
            }
        }

        .a-progressBar {
            .-bar {
                .-progress {
                    transition: width 0.3s linear;
                }
            }
        }
    }

    .-searchButton {
        .a-btn {
            width: 100%;
        }
    }

    .adciImage {
        height: 100px;
    }

    @keyframes childReveal {
        to {
            opacity: 1;
        }
    }

    @keyframes childHide {
        to {
            display: none;
        }
    }

    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
