.m-toggler {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 14px;
    width: 14px;
    height: 14px;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    @include media-up(l) {
        display: none;
        margin-right: 20px;
    }

    @include media-down(l) {
        margin-left: 20px;
    }

    &.-admin {
        @include media-down(l) {
            margin-left: 0;
        }

        @include media-up(l) {
            display: flex;
            margin-right: 0;
        }

        @include media-up(xl) {
            display: none;
            margin-right: 20px;
        }
    }

    span {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 1.5px;
        min-height: 1.5px;
        margin: 2px auto;
        background-color: $dark-blue;
        border-radius: 50px;
        transition: 0.3s ease-in-out;

        &:first-of-type {
            top: 0;
        }

        &:last-of-type {
            bottom: -1px;
        }
    }

    .-sidebarIsOpen & {
        span {
            &:first-of-type {
                transform: rotate(45deg);
                transform-origin: left;
            }

            &:nth-of-type(2) {
                transform: translateX(150px);
            }

            &:last-of-type {
                transform: rotate(-45deg);
                transform-origin: left;
            }
        }
    }

    &.-movedRight {
        margin-right: -80px;
    }
}
