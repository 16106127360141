.m-tab {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &.-underlined {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            height: 2px;
            width: 100%;
            background-color: $dark-blue-20;
        }

        .m-scrollableTabs__tab {
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                display: block;
                height: 2px;
                width: 100%;
                background-color: $light-blue;
                transition: transform 0.3s;
                transform: scaleX(0);
                pointer-events: none;
                z-index: -1;
            }

            &:hover {
                color: $light-blue-80;
                background-color: transparent;
            }

            &.-active {
                color: $light-blue;
                background-color: transparent;

                &::after {
                    transform: scaleX(1);
                    z-index: 1;
                }
            }
        }
    }
}

.m-scrollableTabs {
    position: relative;
    padding: 0;
    overflow: hidden;
    transition: 0.3s ease-in-out;

    @include media-down(m) {
        padding: 0;
    }

    .-wrapper {
        display: flex;
        margin: 0;
        gap: 10px;
        list-style: none;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__tab {
        position: relative;
        color: $dark-blue-40;
        border-radius: 5px;
        background-color: transparent;
        text-decoration: none;
        padding: 0 10px;
        height: 35px;
        display: flex;
        align-items: center;
        user-select: none;
        white-space: nowrap;
        transition: 0.3s ease-in-out;

        &:hover {
            color: $dark-blue-50;
            background-color: $dark-blue-05;
        }

        &.-active {
            color: $light-blue;
            background-color: $light-blue-05;

            &:hover {
                background-color: $light-blue-10;
            }
        }

        &.-disabled {
            pointer-events: none;
            color: $dark-blue-40;
            background-color: $dark-blue-05;
        }

        .-incomplete {
            position: absolute;
            top: 10px;
            right: 0;
            transform: translate(-3px, -3px);
            width: 6px;
            height: 6px;
            background-color: $red;
            border-radius: 100%;
        }
    }

    .rightArrow,
    .leftArrow {
        z-index: 1;
        position: absolute;
        top: 0;
        opacity: 0;
        visibility: hidden;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 40px;
        background-color: $white;
        border: none;
        padding: 0;
        cursor: pointer;
        transition: 0.3s ease-in-out;

        &::after {
            z-index: 1;
            content: '';
            position: absolute;
            top: 0;
            width: 30px;
            height: 100%;
            display: none;
        }

        &:focus,
        &:hover {
            outline: none;
            border-style: none;
        }

        @include media-down(m) {
            display: none;
        }
    }

    .leftArrow {
        left: 0;
        transform: translateX(-100%);

        &::after {
            right: -30px;
            background: linear-gradient(
                90deg,
                $white 0%,
                rgba(255, 255, 255, 0) 100%
            );
        }
    }

    .rightArrow {
        right: 0;
        transform: translateX(100%);

        &::after {
            left: -30px;
            background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 0%,
                $white 100%
            );
        }
    }

    &.-isScrollable {
        .rightArrow,
        .leftArrow {
            display: flex;

            &::after {
                display: flex;
            }
        }
    }

    &.-withRightArrow {
        .rightArrow {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }
    }

    &.-withLeftArrow {
        .leftArrow {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }
    }
}
