.a-notificationsList {
    display: flex;
    flex-direction: column;
    width: 100%;

    .a-notification {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        padding: 15px 20px;
        border-radius: 10px;
        border: 1px solid $light-blue;
        background-color: $light-blue-05;


        &.-invitation {
            border: 1px solid $light-blue;
            background-color: $light-blue-05;
        }

        &__content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            @include media-down(l) {
                flex-direction: column;
                align-items: flex-start;

                h3 {
                    margin-bottom: 15px;
                    padding-right: 45px;
                }
            }

            .-column {
                display: flex;
                flex-direction: column;
                flex: 0 0 35%;
                max-width: 35%;

                @include media-down(l) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin-bottom: 10px;
                }
            }

            .-row {
                display: flex;

                svg {
                    margin-right: 10px;
                }

                .-label {
                    margin-right: 10px;
                    white-space: nowrap;
                }
            }

            .a-btn {
                flex: 0 0 30%;
                max-width: 290px;
                width: 100%;

                @include media-down(l) {
                    margin-top: 20px;
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }

        &.-expiredNotification {
            border: 1px solid $red;
            background-color: $red-10;
            color: $red;

            h3,
            .-label {
                color: $red;
            }

            .-close span {
                background-color: $red;
            }
        }

        &.-expiringNotification {
            border: 1px solid $orange;
            background-color: $orange-10;
            color: $orange;

            h3 {
                color: $orange;
            }

            .-close span {
                background-color: $orange;
            }
        }
    }
}