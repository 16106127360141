.m-packagesPage {
    &__heading {
        .-description {
            max-width: 490px;
        }

        @include media-down(m) {
            padding: 30px 20px 0 !important;
        }
    }

    &__navigation {
        .m-tab {
            justify-content: center;

            .-wrapper {
                @include media-down(xs) {
                    gap: 0;
                }
            }
        }
    }

    &__table {
        table {
            height: 1px; //this is where magic happens
            border: none;
            padding: 0;

            td,
            th {
                border: none;
                padding: 0;

                & > div {
                    width: 100%;
                    height: 100%;
                }

                &:first-of-type {
                    & > div {
                        border-left-color: transparent;
                        @include media-up(m) {
                            padding-left: 20px;
                        }

                        @include media-up(xl) {
                            padding-left: 50px;
                        }
                    }
                }
            }

            tr {
                th {
                    & > div {
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }
                td {
                    height: 100%;
                    min-width: 150px;
                    width: 100%;

                    @include media-up(xl) {
                        min-width: 250px;
                    }

                    & > div {
                        padding-left: 20px;
                        padding-right: 20px;

                        @include media-up(m) {
                            outline: 1px solid $dark-blue-05;
                        }

                        @include media-down(m) {
                            border-bottom: 1px solid $dark-blue-05;
                        }
                    }
                }
            }

            .-categoryRow {
                background-color: $dark-blue-05;
            }

            .-packageInfoRow {
                td {
                    outline: none;

                    & > div {
                        padding-left: 15px;
                        padding-right: 15px;
                        border-radius: 20px 20px 0 0;
                    }

                    @include media-down(l) {
                        .a-btn span {
                            white-space: normal;
                            min-width: 150px;
                        }
                    }
                }

                &.-bottom {
                    td {
                        & > div {
                            margin-top: 0;
                            border-radius: 0 0 20px 20px;
                        }
                    }
                }
            }
        }

        &:not(.-active) {
            table {
                tr {
                    @include media-down(m) {
                        td:first-of-type,
                        th:first-of-type {
                            & > div {
                                border-left: 1px solid $dark-blue-10;
                            }
                        }

                        td:last-of-type,
                        th:last-of-type {
                            & > div {
                                border-right: 1px solid $dark-blue-10;
                            }
                        }
                    }
                }

                .-packageInfoRow {
                    td {
                        @include media-down(m) {
                            &:nth-child(1) {
                                & > div {
                                    border-top: 1px solid $dark-blue-10;
                                }
                            }
                        }
                    }

                    &.-bottom {
                        td {
                            @include media-down(m) {
                                &:nth-child(1) {
                                    & > div {
                                        border-bottom: 1px solid $dark-blue-10;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .-supportNoteRow {
            td {
                & > div {
                    padding: 0;
                    outline: none;
                    border: none !important;
                    padding-right: 20px;

                    @include media-down(m) {
                        padding-left: 20px;
                    }
                }
            }
        }
    }
}
