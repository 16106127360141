.a-iconShowcase {
    position: relative;
    display: flex;
    width: max-content;
    height: max-content;
    cursor: pointer;

    .iconShowcase-content {
        z-index: 3;
        position: absolute;
        top: 8px;
        display: none;
        align-items: center;
        gap: 15px;
        max-height: max-content;
        max-width: 400px;
        width: max-content;
        border-radius: 10px;
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
        padding: 20px;
        background-color: $white;
        white-space: normal;
        overflow-wrap: break-word;
        pointer-events: auto;

        div {
            svg {
                min-width: min-content;
            }
        }

        &.hidden {
            visibility: hidden !important;
        }

        &.-left {
            .-badge {
                left: unset !important;
                right: calc(100% - 30px);
            }
        }

        &.-middle {
            .-badge {
                left: calc(100% - 180px);
            }
        }
    }

    &:hover .iconShowcase-content,
    &:focus-within .iconShowcase-content {
        display: flex;
        opacity: 1;
        visibility: visible;
    }
}
