.a-chevron {
    display: inline-block;
    width: 5px;
    height: 5px;
    margin-top: -3px;
    border: 1.5px solid $dark-blue-40;
    border-width: 3px;
    border-top-color: transparent;
    border-left-color: transparent;
    transition: .3s ease-in-out;
    cursor: pointer;

    &.-up {
        border-right-color: rgba($dark-blue-40, 0.5);
        border-bottom-color: rgba($dark-blue-40, 0.5);
        transform: rotate(225deg) translateY(-6px);
    }

    &.-down {
        border-right-color: $dark-blue-40;
        border-bottom-color: $dark-blue-40;
        transform: rotate(45deg);
    }
}