.m-modal {
    opacity: 0;
    z-index: 20;
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    width: 100vw;
    height: 100%;
    background-color: $dark-blue-50;
    pointer-events: none;
    overflow-x: hidden;
    overflow-y: auto;
    transition: 0.3s ease-in-out;
    @include scrollbar;

    &.-show {
        opacity: 1;
        pointer-events: initial;

        .m-modal__content {
            margin-top: 0;
        }
    }

    &.-small {
        .wrapper {
            width: min-content;
        }
    }

    &.-custom {
        .wrapper {
            width: fit-content;
            max-width: 80vw;
        }

        .m-modal__content {
            max-height: calc(100vh - 40px);
        }
    }

    .wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        border-radius: 10px;
        max-width: 80vw;
        width: 1030px;

        @include media-up(xl) {
            max-width: 1030px;
        }

        @include media-down(l) {
            padding-top: 0;
            height: 100%;
            max-width: 100vw !important;
            width: 100vw !important;
            border-radius: 0;
        }
    }

    .-modalElements {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: space-between;
    }

    &.-smallModal {
        &.-wider {
            .wrapper {
                width: 1000px;
            }
        }

        &.-confirmationModal {
            z-index: 22;
        }

        .-insideContent > .removeItemIcon {
            top: 35px;
            right: 40px;
        }

        .wrapper {
            width: 610px;
        }

        .-header {
            min-height: auto;
            border-bottom: none;
            padding: 30px 40px 10px;

            span {
                font-size: 20px;
                line-height: 24px;
                font-weight: 600;
                word-break: break-word;
            }
        }

        .-footer {
            min-height: auto;
            border-top: none;
            padding: 10px 30px 40px;

            @include media-down(l) {
                padding: 10px 20px 40px;
            }
        }

        .-bodyContent {
            padding: 20px 30px;

            @include media-down(l) {
                padding: 20px;
            }
        }

        .-body {
            height: auto;
        }

        .m-modal__content {
            max-height: none;
        }
    }

    &.-widerSmallModal {
        .wrapper {
            width: 1030px;
        }
    }

    &.-verificationModal {
        .verificationLink {
            display: flex;
            gap: 20px;

            @include media-down(xs) {
                flex-wrap: wrap;
                justify-content: center;
                gap: 5px;
            }

            .m-inputGroup {
                width: 100%;
                margin-bottom: 0;
            }
        }

        .a-btn.-tertiary {
            width: unset;
        }
    }

    &.unitsModal {
        .wrapper {
            width: 610px;
        }

        .a-centeredText {
            @include media-down(m) {
                text-align: left;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        max-height: calc(100vh - 100px);

        @include media-down(l) {
            max-height: 100vh;
        }

        .fullWidth {
            position: relative;
        }

        ._wr {
            padding-left: 0;
            padding-right: 0;

            ._w {
                margin-left: 0;
                margin-right: 0;
            }

            @include media-down(l) {
                max-width: unset;
                height: 100%;

                ._w {
                    margin-left: 0;
                }
            }
        }

        &--text {
            display: flex;
            flex-direction: column;
            align-items: flex-start !important;
            width: 100%;

            span {
                width: 100%;
                text-align: center;
            }
        }

        .-insideContent {
            width: 100%;
            border-radius: 10px;
            box-shadow:
                0 4px 8px 0 rgba($dark-blue, 0.2),
                0 6px 20px 0 rgba($dark-blue, 0.19);
            background-color: $white;
            max-height: calc(100vh - 100px);

            @include media-down(l) {
                height: 100%;
                max-height: unset;
                min-height: 100%;
                border-radius: 0;
            }
        }

        scroll-padding-right: 10px !important;

        &::-webkit-scrollbar-track-piece:end {
            margin-bottom: 155px;
        }

        &::-webkit-scrollbar-track-piece:start {
            margin-top: -80px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__buttons {
        display: flex;
        width: 100%;
        margin-top: 20px;

        &.-end {
            justify-content: flex-end;
        }

        .a-btn {
            width: 100%;
            height: 50px;

            @include media-up(s) {
                white-space: nowrap;
            }

            div {
                justify-content: center !important;
            }

            &:not(:last-child) {
                margin-right: 20px !important;
            }
        }
    }

    .removeItemIcon {
        z-index: 2;
        position: absolute;
        top: 25px;
        right: 25px;
        width: 14px;
        height: 14px;

        &::before,
        &::after {
            background-color: $dark-blue;
            height: 1.5px;
        }
    }

    &__body {
        height: 100%;
        min-height: 100%;

        .-buttons {
            display: flex;

            &:not(.space-between) {
                @include media-up(xs) {
                    & > div:first-of-type {
                        padding-right: 10px;
                    }

                    & > div:last-of-type {
                        padding-left: 10px;
                    }
                }
            }

            .a-btn:not(.-tertiary) {
                width: 100% !important;
                margin-left: 0 !important;

                &.-tertiary {
                    height: 10px;
                }
            }

            & > div:last-of-type {
                @include media-down(xs) {
                    order: -1;
                }
            }
        }

        .-form {
            @include media-down(m) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                min-height: calc(100vh - 60px);
            }
        }

        .-title {
            margin-bottom: 20px;
            text-align: center;
        }

        .m-inputGroup svg {
            top: 50px;
        }

        @include media-down(l) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .m-addAndUpload {
        height: 100%;
    }

    &__form {
        @include media-up(l) {
            min-width: 530px;
        }

        .-buttons {
            display: flex;

            @include media-down(xs) {
                flex-direction: column;
            }
        }

        @include media-down(s) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
    }

    //MINA TO DO: Remove all of this if it is not necessary
    &.personalInformation {
        .m-addAndUpload {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            & + .errorMessage {
                white-space: nowrap;
            }
        }

        @include media-down(m) {
            .personalInfoModal {
                padding-bottom: 290px;
            }
        }
    }

    .-headerTitle {
        word-break: break-word;
    }

    .-paddingRight {
        padding-right: 45px;
    }

    &.-imagePreviewModal {
        z-index: 21;

        .-imagePreview {
            max-width: 100%;
            max-height: 620px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            img {
                border: 1px solid $dark-blue-05;
                border-radius: 10px;
                object-fit: contain;
                height: 100%;
            }
        }
    }

    &.-pdfModal {
        .react-pdf__message.react-pdf__message--error {
            color: $dark-blue-60;
            font-size: 12px;
            font-weight: 400;
        }

        .react-pdf__Document {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 620px;

            .react-pdf__Page {
                max-width: 480px;
                min-width: unset !important;
                max-height: 680px;
                border-radius: 10px;
                border: 1px solid #d9d9d9;
                overflow: hidden;

                canvas {
                    width: 100% !important;
                    height: 100% !important;
                }
            }
        }

        .-pageControls {
            z-index: 2;
            position: absolute;
            transform: translateY(calc(-100% - 10px));
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            & > div {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 12px 20px;
                border-radius: 4px;
                box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
                background-color: $white;

                .a-btn {
                    margin-top: 0;
                    padding: 0;
                    height: 16px !important;
                }

                span {
                    margin: 0 15px;
                    white-space: nowrap;
                }
            }
        }
    }

    .-divingModes {
        width: 100%;

        .-fieldsError {
            margin-top: -10px;
            display: none;
        }

        &:has(.m-inputGroup .errorMessage.-active) {
            .m-inputGroup .errorMessage {
                display: none;
            }

            .a-input {
                border: 1px solid $red;
            }

            .-fieldsError {
                display: block;
            }
        }
    }

    &.experience-modal {
        .-totalDivesNumberActive .-divingModes:not(.-totalNumbersOfDives),
        .-totalDivesNumberDisabled .-totalNumbersOfDives {
            input {
                background-color: $dark-blue-05;
                border: 1px solid $dark-blue-05;
            }
        }
    }

    &.-diveTeamMembersList {
        & > .wrapper {
            @include media-up(l) {
                height: 80vh;
                min-height: 400px;
            }
        }
    }

    &.-rejectionModal {
        .centered-checkbox .a-checkbox {
            margin-top: 0;
        }
    }

    .-header,
    .-footer {
        background: $white;
        width: 100%;
    }

    .-header {
        min-height: 64px;
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid $dark-blue-20;
        padding: 20px 0;

        @include media-down(l) {
            border-radius: 0;
        }
    }

    .-footer {
        min-height: 80px;
        border-radius: 0 0 10px 10px;
        border-top: 1px solid $dark-blue-20;
        padding: 15px 20px;

        .-prevButton,
        .-middleButton,
        .-nextButton {
            display: flex;
            align-items: center;
            justify-content: center;

            .a-btn {
                width: 100%;
            }
        }

        @include media-down(l) {
            border-radius: 0;
        }

        @include media-down(m) {
            .-prevButton {
                margin-top: 20px;
            }

            .-nextButton {
                order: -1;
            }

            .-middleButton {
                margin-bottom: 10px;
            }
        }
    }

    .-body {
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: $white;

        .m-inputGroup.-icon svg {
            top: 50px;
        }

        @include scrollbar;

        @include media-down(l) {
            height: 100vh;
            max-height: calc(100vh - 70px);
            margin-left: 0;
        }

        &Content {
            width: 100%;
            height: 100%;
            margin-left: 0;
            padding: 20px;
            background-color: $white;
        }
    }

    &.-smallModalWithDropdowns {
        .-body {
            @include media-down(m) {
                overflow: auto !important;
            }

            &Content {
                @include media-down(l) {
                    display: flex;
                    justify-content: center;

                    & > div {
                        flex: 0 0 auto;
                    }
                }
            }
        }
    }

    &.-withoutButtons {
        .-body {
            border-radius: 0 0 10px 10px;
            max-height: calc(100vh - 100px);

            @include media-down(l) {
                border-radius: 0;
            }
        }
    }

    &.-withoutTitle {
        .-body {
            border-radius: 10px 10px 0 0;
            max-height: 70vh;

            @include media-down(l) {
                border-radius: 0;
            }
        }
    }

    &.-formInForm {
        .m-modal__content ._wr {
            height: unset;
        }

        .-body {
            border-radius: 0;
        }
    }

    &.-hidePrevBtn {
        .wrapper {
            width: 400px;
            max-width: 80vw;
        }
    }
}
