.m-sidebar {
    // NOTE: z-index: 10 due to the floating chat
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: $sidebar-width;
    height: 100vh;
    border-left: 1px solid $dark-blue-20;
    background-color: $white;
    filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.05));
    transition: 0.3s ease-in-out;

    @include media-down(xs) {
        width: 100vw;
        padding-top: 60px;

        .-mainLogo {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    @include media-down(l) {
        right: 0;
        left: unset;
    }

    &.-admin {
        @include media-down(xl) {
            right: 0;
            left: unset;
        }
    }

    &.-fixedSidebar {
        z-index: 5;

        .m-sidebar {
            &__scroll {
                padding: 30px 20px;
            }

            &__item {
                height: 40px;
                margin: 0;
                margin-bottom: 10px;
                padding: 10px 20px;

                &.-mainLabel {
                    margin-bottom: 20px;
                }
            }

            &__subitem {
                margin: 0 !important;
            }

            &__itemContainer {
                &.-active {
                    .m-sidebar__subitem {
                        margin-bottom: 10px !important;
                    }
                }
            }
        }

        .a-separator {
            margin: 10px 0 20px 0;
        }
    }

    &.-closed {
        width: $sidebar-closed-width;

        .m-sidebar {
            &__itemLabel {
                opacity: 0;
                width: 0;
                color: $white;
            }

            &__toggler {
                background-color: $light-blue;
                border-color: $light-blue;

                &::after {
                    left: calc(50% - 2px);
                    border-color: $white;
                    transform: translate(-50%, -50%) rotate(-135deg);
                }
            }

            &__itemContainer {
                &.-active {
                    .m-sidebar__subitem {
                        padding: 12px 0;
                    }
                }
            }

            &__subItemsIcon {
                display: none;
            }

            &__subitem {
                transition: 0.3s ease-in-out;

                svg {
                    opacity: 1;
                    width: 24px;
                    transition: 0.3s ease-in-out;
                }
            }

            &__subitemLabel {
                opacity: 0;
                width: 0;
                transition: 0.3s ease-in-out;
                display: none;
            }

            &__subitems {
                padding-left: 25px;
            }
        }

        .m-toggler {
            display: none;
        }
    }

    &.-lowerLevel {
        top: $header-height;
        height: calc(100vh - 60px);

        .m-sidebar__scroll {
            height: calc(100vh - 60px);
        }
    }

    &__scroll {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        @include scrollbar;
    }

    &__item {
        position: relative;
        outline: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 60px;
        margin: 0 20px 20px 20px;
        border-radius: 100px;
        background-color: $white;
        padding: 20px;
        color: $dark-blue;
        transition: 0.3s ease-in-out;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;

        &.-active {
            background-color: $dark-blue-05;

            .m-sidebar__subItemsIcon {
                &::after {
                    transform: translate(-50%, -50%) rotate(135deg);
                }
            }
        }

        &:not(.-mainLabel):hover {
            background-color: $dark-blue-05;
        }

        svg {
            min-width: 24px;
            min-height: 24px;
            margin-right: 10px;
            transition: 0.3s ease-in-out;
        }
    }

    &__itemLabel {
        opacity: 1;
        width: 100%;
        color: $dark-blue;
        overflow: hidden;
        white-space: nowrap;
        transition: 0.3s ease-in-out;
    }

    &__toggler {
        z-index: 2;
        position: absolute;
        top: 30px;
        right: -17px;
        display: block;
        width: 30px;
        height: 30px;
        border: 1px solid $dark-blue-20;
        border-radius: 50%;
        background-color: $white;
        transition: 0.3s ease-in-out;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 7px;
            height: 7px;
            border: 1.5px solid $dark-blue-20;
            border-top: 0;
            border-right: 0;
            transform: translate(-50%, -50%) rotate(45deg);
            transition: 0.3s ease-in-out;
        }
    }

    &__subItemsIcon {
        position: absolute;
        right: 20px;
        transition: 0.3s ease-in-out;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            transform: translate(-50%, -50%) rotate(-45deg);
            width: 7px;
            height: 7px;
            border: 1.5px solid $dark-blue;
            border-top: 0;
            border-right: 0;
            transition: 0.3s ease-in-out;
        }
    }

    &__itemContainer {
        display: flex;
        flex-direction: column;

        .m-sidebar__subitemLabel {
            pointer-events: none;
        }

        .m-sidebar__subitem {
            svg {
                pointer-events: none;
            }
        }

        &.-active {
            height: max-content;

            .m-sidebar__subitem {
                opacity: 1;
                max-height: 40px;
                padding: 10px 30px;
                margin: 0 20px 20px 20px;
                transition: 0.3s ease-in-out;
                border-radius: 100px;

                &:hover {
                    background-color: $dark-blue-05;
                }

                &.-active {
                    background-color: $dark-blue-05;
                }

                svg {
                    pointer-events: all;
                }
            }

            .m-sidebar__subItemsIcon {
                &::after {
                    transform: translate(-50%, -50%) rotate(135deg);
                }
            }

            .m-sidebar__subitemLabel {
                pointer-events: all;
            }
        }
    }

    &__subitems {
        display: flex;
        flex-direction: column;
        background-color: $white;
        transition: 0.3s ease-in-out;
    }

    &__subitem {
        opacity: 0;
        position: relative;
        display: flex;
        align-items: center;
        max-height: 0;
        transition: 0.3s ease-in-out;
        white-space: nowrap;
        cursor: pointer;

        &.-active {
            svg {
                stroke: $light-blue;
            }
        }

        &:hover {
            svg {
                stroke: $light-blue;
                transition: 0.3s ease-in-out;
            }
        }

        svg {
            opacity: 0;
            width: 0;
            stroke: $dark-blue;
            transition: 0.3s ease-in-out;
        }
    }

    &__subitemLabel {
        opacity: 1;
        display: block;
        width: auto;
        color: $dark-blue;
        font-size: 14px;
        letter-spacing: -0.4px;
        line-height: 30px;
        transition: 0.3s ease-in-out;
    }

    .m-avatar {
        &__imgInfo {
            justify-content: start;
            height: 60px;
            margin: 30px 20px 20px 20px;
            border-radius: 100px;
            background-color: $dark-blue-05;
            padding: 5px 20px 5px 5px;
        }

        &__img {
            width: 50px;
            min-width: 50px;
            height: 50px;
            min-height: 50px;
        }

        &__icon {
            width: 100%;
            height: 100%;
        }

        &__name {
            display: flex;

            .-name,
            .-lastName {
                display: block;
            }
        }
    }

    .-logout {
        margin: 0 20px 20px 20px;
        padding: 20px !important;
        border-radius: 100px;

        svg {
            min-width: 24px;
            min-height: 24px;
            margin-right: 10px;
        }
    }

    .main-logo {
        align-self: center;
    }

    .a-separator {
        margin: 0 20px 20px 20px;
        border-color: $dark-blue-20;
    }

    .m-toggler {
        position: absolute;
        top: 24px;
        right: 17px;

        span {
            &:first-of-type {
                transform: rotate(45deg);
                transform-origin: left;
            }

            &:last-of-type {
                transform: rotate(-45deg);
                transform-origin: left;
            }
        }
    }

    .personalProfile {
        .m-avatar__imgInfo {
            margin-top: 0;
        }
    }
}
