// NOTE: The default chat icons needed to be rewritten. Pay attention to the colors when changing (stroke='%230177a9; %23 is actually a # symbol, so the 0177a9 is the hex code of the color).
// content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke='%230177a9' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M9 12H15' stroke='%230177a9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 8.99976L12 14.9998' stroke='%230177a9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");

.m-floatingIcon {
    z-index: 9;
    position: fixed;
    right: 50px;
    bottom: 50px;

    .floating-icon-chat {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        background: $light-blue;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2));
        border-radius: 50%;
        cursor: pointer;
    }

    .unread-message {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        background-color: $red;
        border-radius: 100%;
        color: $white;
        transition: 0.3s ease-in-out;

        &.-active {
            opacity: 1;
            visibility: visible;
        }
    }
}

.fullscreen-chat {
    width: 100%;
    .chat-cmp {
        position: relative;
        width: auto;
        right: unset;
        bottom: 0;
        height: calc(100vh - 125px);

        .main-chat-content {
            width: 70%;
            border-radius: 10px;

            @include media-down(m) {
                width: 100%;
            }
        }

        .chat-sidebar {
            width: 30%;
            border-radius: 10px;

            @include media-down(m) {
                width: 100%;
                max-width: unset;
            }
        }
    }
}

.chat-cmp {
    z-index: 2;
    position: fixed;
    bottom: 0;
    right: 5px;
    height: calc(100vh - 70px);
    display: flex;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    background: transparent;

    button:focus {
        outline: none;
    }

    .no-channels-found {
        display: flex;
        margin-top: 10px;

        p {
            font-size: 12px;
            color: #a2a5b1;
        }
    }

    .header-wrapper {
        display: flex;
        justify-content: space-between;

        .close-chat {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .channel-preview {
        display: block;
        width: 100%;
        max-width: 360px;
        border: none;
        border-radius: 4px;
        background: $white;
        padding: 10px;
        cursor: pointer;
        transition: 0.3s ease-in-out;

        &__item {
            display: flex;
            align-items: center;

            p {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-align: left;
                text-overflow: ellipsis;
            }
        }

        &.-active {
            background-color: $light-blue-05;
        }
    }

    .chat-users-search-result {
        display: flex;
        align-items: center;
        min-height: 50px;
        width: 100%;
        height: 50px;
        padding: 10px;
        word-break: break-word;
        cursor: pointer;
        justify-content: space-between;
        border-radius: 4px;
        background: $white;
        transition: 0.3s ease-in-out;

        &.-active {
            background: $light-blue-05;
        }

        .result-wrapper {
            width: calc(100% - 30px);
            display: flex;
            align-items: center;
            gap: 10px;

            .-resultImg {
                position: relative;
                border-radius: 100%;
                width: 30px;
                height: 30px;
                min-width: 30px;
                min-height: 30px;
                border: 0.5px solid $dark-blue-20;
                background-color: $dark-blue-05;

                .m-avatar__icon {
                    background-color: transparent;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 100%;
                }
            }

            .-resultText {
                width: calc(100% - 40px);
            }

            .-nameWrapper {
                width: 100%;

                span {
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .removeItemIcon {
            width: 15px;
            height: 15px;

            &::before,
            &::after {
                background-color: $dark-blue;
                height: 1.5px;
            }
        }
    }

    .chat-users-search-result,
    .channel-preview {
        position: relative;
        overflow: visible;

        .-dropdownActions {
            opacity: 0;
            visibility: hidden;
            transition: 0.3s ease-in-out;
        }

        &:hover {
            background-color: $light-blue-05;

            .-dropdownActions {
                opacity: 1;
                visibility: visible;
            }
        }

        &.-noHover {
            cursor: default;

            &:hover {
                background-color: transparent;
            }
        }
    }

    .chat-sidebar {
        background: $white;
        width: 50%;
        max-width: 350px;
        margin-right: 10px;
        border-radius: 10px 10px 0 0;
        border: 1px solid $dark-blue-20;
        overflow: hidden;

        .-sidebarContent {
            height: 100%;
            padding: 22px 20px 20px 20px;
        }

        .search-wrapper {
            position: relative;
        }

        .sidebar-content {
            height: calc(100% - 100px);
            max-height: calc(100% - 100px);
        }

        .channels-sidebar,
        .users-sidebar {
            max-height: calc(50% - 25px);
            overflow: auto;
            @include scrollbar;

            .str-chat {
                border: none;
            }
        }

        .new-channel {
            display: flex;
            justify-content: space-between;

            .icon-holder {
                height: 24px;
                svg {
                    cursor: pointer;
                }
            }
        }
    }

    .main-chat-content {
        border: 1px solid $dark-blue-20;
        background: $white;
        width: 430px;
        min-width: 430px;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
    }

    .add-chanel-wrapper,
    .add-dm-wrapper {
        display: flex;
        flex-direction: column;
        background: $white;
        padding: 20px;
        position: relative;
        height: 100%;
        overflow: auto;
        @include scrollbar;

        &.-edit {
            height: calc(100% - 72px);
            justify-content: space-between;
        }

        .-listOfStatuses {
            margin-bottom: 15px;

            span {
                margin-right: 5px;

                .remove-from-selected {
                    cursor: pointer;
                    margin-right: 0;
                    margin-left: 7px;
                }
            }
        }

        .channel-users-search {
            z-index: 2;
            position: absolute;
            top: calc(100% - 2px);
            width: 100%;
            padding-bottom: 20px;

            ul {
                border: 1px solid $dark-blue-20;
                border-radius: 0 0 4px 4px;
                background-color: $white;
                max-height: 250px;
                overflow: auto;
                @include scrollbar;
            }

            .-result {
                display: flex;
                align-items: center;
                min-height: 50px;
                padding: 10px;
                word-break: break-word;
                cursor: pointer;
                gap: 10px;

                .-resultImg {
                    border-radius: 100%;
                    width: 30px;
                    height: 30px;
                    min-width: 30px;
                    min-height: 30px;
                    border: 0.5px solid $dark-blue-20;
                    overflow: hidden;
                }
            }
        }

        .create-channel-btn-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
        }
    }

    .m-searchField {
        min-width: 0;

        .a-input {
            padding-right: 15px;
            padding-left: 40px;
        }

        svg {
            left: 15px;
            right: unset;
        }

        &.-withLabel {
            position: relative;

            label {
                margin-bottom: 5px;
            }

            .m-inputGroup.-icon svg {
                margin-top: 13px;
            }

            .a-input {
                height: 50px;
            }
        }
    }

    .str-chat {
        &__channel-header {
            padding: 15px;
            border-bottom: 1px solid $dark-blue-20;

            &.-channels {
                height: 72px;
                padding-left: 20px;
            }

            .-actionIcon {
                position: absolute;
                top: 20px;
                right: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

            .m-avatar__popup {
                right: 20px;
            }
        }

        &__message {
            &--me {
                .str-chat__message-bubble {
                    background-color: $light-blue-10 !important;
                }
            }
            &-bubble {
                background-color: $dark-blue-05;
            }
        }

        &__avatar {
            border: 0.5px solid $dark-blue-20;
            border-radius: 100%;
            overflow: hidden;

            &-image {
                background-color: $white !important;
            }
        }

        &__file-input-label {
            svg[data-testid='attach-icon'] {
                display: none;
            }

            &:before {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 5px;
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke='%230177a9' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M9 12H15' stroke='%230177a9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 8.99976L12 14.9998' stroke='%230177a9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            }
        }

        &__message-input {
            padding: 0 20px 20px 20px;
        }

        &__message-input,
        &__main-panel-inner {
            &:focus-visible {
                outline: none;
            }
        }

        &__message-textarea-container {
            border-radius: 4px;
            border: 1px solid $dark-blue-20;
            transition: 0.3s ease-in-out;

            textarea {
                min-height: 100%;

                &::placeholder {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                }
            }

            &:has(textarea:focus) {
                border: 1px solid $light-blue;
            }
        }

        &__message-textarea {
            @include scrollbar;
        }

        &__message-textarea-with-emoji-picker {
            padding: 15px 35px 15px 15px !important;
            column-gap: 15px !important;
        }

        &__send-button {
            position: absolute !important;
            right: 30px;
            bottom: 41px;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            width: 24px !important;
            min-width: unset !important;
            max-width: 20px !important;
            height: 20px !important;

            &:disabled::before {
                max-height: 24px;
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M6.32811 12.3544H9.15654M5.70533 13.1498L4.04502 19.1873C3.76966 20.1886 4.80989 21.0432 5.73872 20.5788L20.041 13.4277C20.9254 12.9854 20.9254 11.7233 20.041 11.281L5.73873 4.12993C4.80989 3.66551 3.76967 4.52013 4.04503 5.52143L5.70533 11.5588C5.84851 12.0795 5.84851 12.6291 5.70533 13.1498Z' stroke='%23A2A5B1' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            }

            &::before {
                max-height: 24px;
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M6.32811 12.3544H9.15654M5.70533 13.1498L4.04502 19.1873C3.76966 20.1886 4.80989 21.0432 5.73872 20.5788L20.041 13.4277C20.9254 12.9854 20.9254 11.7233 20.041 11.281L5.73873 4.12993C4.80989 3.66551 3.76967 4.52013 4.04503 5.52143L5.70533 11.5588C5.84851 12.0795 5.84851 12.6291 5.70533 13.1498Z' stroke='%230177a9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            }

            svg[data-testid='send'] {
                display: none;
            }
        }

        &__message-status {
            svg path {
                fill: $light-blue;
            }
        }

        &__main-panel-inner {
            @include scrollbar;
        }

        &__message-textarea-emoji-picker {
            margin-right: 10px;
        }

        &__emoji-picker-button {
            &:focus {
                outline: none;
            }

            svg {
                display: none;
            }

            &::before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke='%230177a9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 14C8 14 9.5 16 12 16C14.5 16 16 14 16 14' stroke='%230177a9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9 9H9.01' stroke='%230177a9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15 9H15.01' stroke='%230177a9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            }
        }

        &__li {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }

        &__list {
            @include scrollbar;
        }

        &__thread-container {
            z-index: 2;
            position: absolute;
        }

        &__message-replies-count-button-wrapper button {
            color: $light-blue;
        }

        &__unread-messages-notification {
            background-color: $light-blue-60;
        }

        &__unread-messages-separator-wrapper {
            margin-top: 15px;
            padding: 0;
        }

        &__unread-messages-separator {
            background-color: transparent;
            border-top: 1px solid $dark-blue-20;
            color: $light-blue;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
        }

        &__message-reactions {
            margin-bottom: -2px;
        }

        &__message-reaction {
            background-color: transparent !important;
        }

        &__modal__inner {
            width: 610px;

            @include media-down(l) {
                width: 80vw;
            }

            .str-chat__message-textarea-emoji-picker {
                margin-right: 0;
            }

            .str-chat__message-textarea-with-emoji-picker {
                padding-right: 15px !important;
            }
        }

        &__empty-channel {
            &::before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 22 22' fill='none'%3E%3Cpath d='M1.23903 19.0292L0.531876 18.7793H0.531876L1.23903 19.0292ZM2.77027 20.5605L3.02015 21.2676H3.02015L2.77027 20.5605ZM6.15817 19.7518L5.79451 20.4077L6.15817 19.7518ZM2.10104 15.5662L2.76804 15.2233L2.10104 15.5662ZM2.16731 16.4022L2.87446 16.6521L2.16731 16.4022ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75V0.25C5.06294 0.25 0.25 5.06294 0.25 11H1.75ZM2.76804 15.2233C2.11751 13.9581 1.75 12.523 1.75 11H0.25C0.25 12.7671 0.676973 14.4367 1.43404 15.9092L2.76804 15.2233ZM1.94618 19.2791L2.87446 16.6521L1.46016 16.1523L0.531876 18.7793L1.94618 19.2791ZM2.52039 19.8533C2.16372 19.9793 1.82014 19.6358 1.94618 19.2791L0.531876 18.7793C-0.0142717 20.3249 1.47455 21.8138 3.02015 21.2676L2.52039 19.8533ZM5.04298 18.9619L2.52039 19.8533L3.02015 21.2676L5.54274 20.3762L5.04298 18.9619ZM11 20.25C9.37394 20.25 7.84798 19.8311 6.52184 19.0958L5.79451 20.4077C7.33751 21.2632 9.11303 21.75 11 21.75V20.25ZM20.25 11C20.25 16.1086 16.1086 20.25 11 20.25V21.75C16.9371 21.75 21.75 16.9371 21.75 11H20.25ZM11 1.75C16.1086 1.75 20.25 5.89137 20.25 11H21.75C21.75 5.06294 16.9371 0.25 11 0.25V1.75ZM5.54274 20.3762C5.60823 20.3531 5.7001 20.3554 5.79451 20.4077L6.52184 19.0958C6.08623 18.8543 5.54889 18.7832 5.04298 18.9619L5.54274 20.3762ZM1.43404 15.9092C1.4814 16.0013 1.48243 16.0893 1.46016 16.1523L2.87446 16.6521C3.04633 16.1657 2.98714 15.6494 2.76804 15.2233L1.43404 15.9092Z' fill='%23D1D2D8'/%3E%3Ccircle cx='6.0498' cy='11.0498' r='1.25' fill='%23D1D2D8'/%3E%3Ccircle cx='11.0498' cy='11.0498' r='1.25' fill='%23D1D2D8'/%3E%3Ccircle cx='16.0498' cy='11.0498' r='1.25' fill='%23D1D2D8'/%3E%3C/svg%3E");
            }

            svg[data-testid='chat-bubble'] {
                display: none;
            }

            &-text {
                margin-top: 15px;
                color: $dark-blue-40;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
            }
        }

        &__channel-list-react {
            margin-right: 5px;
        }

        &__message-reactions-option {
            &:hover {
                background-color: $light-blue-05 !important;
            }

            &-selected {
                background-color: $light-blue-30 !important;

                &:hover {
                    background-color: $light-blue-20 !important;
                }
            }
        }

        &__channel-list-messenger-react__main {
            overflow: visible !important;
            padding-bottom: 25px;
        }

        &__header-hamburger {
            order: 1;
            display: flex !important;
            background-color: transparent;
            border-color: transparent;
            cursor: pointer;
        }

        .-pinnedMessages {
            z-index: 1;
            position: absolute;
            top: 74px;
            left: 0;
            right: 5px;
            max-height: 65px;
            width: 100%;
            border-bottom: 1px solid $dark-blue-20;
            background-color: $white;
            overflow: auto;
            @include scrollbar;

            &.-onlyOne {
                .-messageWrapper {
                    margin-right: 10px;
                }
            }

            .-messageWrapper {
                display: flex;
                align-items: center;
                gap: 10px;
                background-color: $white;
                border: 1px solid $dark-blue-20;
                border-radius: 4px;
                padding: 7px 15px;
                margin: 5px;
                cursor: pointer;

                .-content {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                .-pinned {
                    gap: 10px;
                }
            }
        }

        &__modal {
            z-index: 11;
            background-color: $dark-blue-50;
            backdrop-filter: none;
        }

        &__edit-message-form .str-chat__message-input {
            padding: 0;
        }

        &__edit-message-form-options {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        &__edit-message-send,
        &__edit-message-cancel {
            border-radius: 4px !important;
            padding: 10px 20px !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 21px !important;
        }

        &__edit-message-cancel {
            color: $light-blue !important;
            background-color: $white !important;
            border: 1px solid $light-blue !important;
        }

        &__edit-message-send {
            color: $white !important;
            background-color: $light-blue !important;
            border-color: $light-blue;
            text-transform: none !important;
        }

        &__modal__close-button {
            display: none;
        }

        &__quoted-message-preview,
        .quoted-message-preview {
            width: 100%;
        }

        &__quoted-message-bubble {
            max-width: unset;
        }
    }

    .chatAvatar {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .-backBtn {
            margin-right: 10px;
        }

        .-image {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            overflow: hidden;
            border: 0.5px solid $dark-blue-20;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &.-messageAvatar {
                width: 32px;
                height: 32px;
            }

            &.-messageStatusAvatar {
                width: 15px;
                height: 15px;
            }
        }

        .-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: $dark-blue-05;
        }
    }

    .-onlineIcon {
        position: absolute;
        right: -2px;
        bottom: 2px;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        outline: 2px solid $white;
        background-color: $green;
        overflow: hidden;
        transition: 0.3s ease-in-out;
    }

    .chat-welcome-screen {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 15px;
            max-width: 40%;
            text-align: center;
        }
    }

    .unread-count {
        height: 25px;
        border-radius: 50%;
        background: $light-blue;
        color: $white;
        aspect-ratio: 1 / 1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
    }
}

.search-and-button {
    gap: 20px;

    .a-btn {
        margin-top: 25px;
    }
}

body:has(.str-chat__modal--open) {
    .m-header {
        z-index: 2 !important;
    }
}
