.m-dropdown {
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    width: 100%;
    height: auto;
    max-height: 0;
    transform: translateY(-10px);
    transition: 0.2s ease-in-out;
    overflow-y: hidden;
    overflow-x: hidden;

    .-dropdownContainer {
        border: 1px solid $dark-blue-20;
        border-top: none;
        border-radius: 0 0 4px 4px;
        background-color: $white !important;
        filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.05));
    }

    .-dropdownOptions {
        overflow-y: auto;
        overflow-x: hidden;
        @include scrollbar;
    }

    &.-open {
        opacity: 1;
        visibility: visible;
        max-height: 280px;
        transform: translateY(0);
        padding-bottom: 20px;

        .-dropdownOptions {
            max-height: 252px;

            &.-withExplanation {
                max-height: 200px;
            }
        }
    }

    &.-dropup {
        top: unset !important;
        bottom: 100%;
        transform: translateY(0);
        padding-bottom: 0;

        .-dropdownContainer {
            border-top: 1px solid $dark-blue-20;
            border-bottom: none;
            border-radius: 4px 4px 0 0;
        }
    }

    .-label,
    .-result,
    .-loadMore {
        display: flex;
        align-items: center;
        padding: 0 20px;
    }

    .-label,
    .-loadMore {
        height: 36px;
        color: $dark-blue-60;
    }

    .-result {
        display: flex;
        align-items: center;
        gap: 10px;
        min-height: 50px;
        padding: 7px 15px;
        word-break: break-word;
        cursor: pointer;

        &:not(:first-of-type) {
            border-top: 1px solid $dark-blue-05;
        }

        &:not(.-noHover):hover {
            background-color: $dark-blue-05;
        }

        &.-noHover {
            cursor: default;
        }

        &.-disabled {
            .-box {
                border-color: $dark-blue-10;
            }

            color: $dark-blue-40;
            cursor: default;

            .-customIcon {
                opacity: 0.7;

                svg {
                    cursor: default;
                }
            }
        }

        &.-create {
            color: $light-blue;
        }

        &Img {
            border-radius: 100%;
            width: 30px;
            height: 30px;
            min-width: 30px;
            min-height: 30px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &Text {
            width: calc(100% - 37px);

            .-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .-nameWrapper {
                width: 100%;
            }
        }

        &.-customResult {
            .-iconWrapper {
                width: 30px;
                height: 30px;
                min-width: 30px;
                min-height: 30px;
                background-color: $white;
                border-radius: 100%;
                border: 1px solid $dark-blue-20;
            }
        }

        .-customIcon {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                position: relative;
                top: 0;
                right: 0;
                transform: none;
            }
        }

        &.-explanation {
            border-bottom: 1px solid $dark-blue-05;
        }

        .a-flag {
            margin-right: 0;
        }
    }

    .-multiSelect {
        .-checkbox {
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            min-width: 16px;
            height: 16px;
            min-height: 16px;
            margin-right: 10px;
            border: 1px solid $dark-blue-20;
            border-radius: 4px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $white;
            transition: 0.3s ease-in-out;

            .-insideBox {
                width: 8px;
                height: 8px;
                min-width: 8px;
                background-color: $white;
                border-radius: 2px;
                transition: 0.3s ease-in-out;

                &.-selected {
                    background-color: $dark-blue;
                }
            }
        }
    }

    &__heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 44px;
        padding: 0 20px;

        div {
            display: flex;
            align-items: center;

            svg {
                min-height: 22px;
                min-width: 22px;
            }
        }

        .a-iconBtn {
            margin-left: 10px;
        }
    }

    &__notification {
        display: flex;
        padding: 10px 20px;
        transition: 0.3s ease-in-out;
        cursor: pointer;

        .-imageBox {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
            border-radius: 100%;
            border: 0.5px solid $dark-blue-05;
            margin-right: 10px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 100%;
                overflow: hidden;
            }

            .m-avatar__icon {
                width: 100%;
                height: 100%;
            }

            .-badge {
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 15px;
                height: 15px;
                min-width: 15px;
                min-height: 15px;
                border-radius: 100%;
                overflow: hidden;

                .-img {
                    width: 100%;
                    height: 100%;
                    background-position: center;
                    background-size: contain;
                }

                &.-applied {
                    .-img {
                        background-image: url('../../../images/icons/applied.svg');
                    }
                }

                &.-accepted {
                    .-img {
                        background-image: url('../../../images/icons/accepted.svg');
                    }
                }

                &.-rejected {
                    .-img {
                        background-image: url('../../../images/icons/rejected.svg');
                    }
                }

                &.-noBadge {
                    display: none;
                }
            }

            &.-warning {
                background-color: $orange-10;
            }

            &.-danger,
            &.-rejected {
                background-color: $red-10;
            }

            &.-accepted {
                background-color: $green-10;
            }
        }

        .-info {
            display: flex;
            flex-direction: column;
        }

        .-info div {
            align-items: flex-start;
        }

        .a-iconBtn {
            margin-left: 10px;
            align-items: flex-start;
        }

        .-date {
            margin-top: 5px;
        }

        &:hover {
            background-color: $light-blue-05;
        }

        &.-unread {
            background-color: $light-blue-10;

            &:hover {
                background-color: $light-blue-15;
            }
        }

        .-readUnread {
            margin-left: 7px;
            padding: 0 3px;
            transition: 0.3s ease-in-out;
        }
    }

    &.-notification {
        top: calc(100% + 5px);
        height: max-content;
        max-height: 60vh;
        padding-bottom: 0;

        // Note: 2px due to borders on the dropdownContainer
        .-dropdownOptions {
            max-height: calc(60vh - 2px);
        }

        .-dropdownContainer {
            border-radius: 4px;
            border: 1px solid $dark-blue-20;
        }

        @include media-down(m) {
            position: fixed;
            top: 60px;
            left: 0;
            right: 0;
            width: 100vw !important;
            height: calc(100vh - 60px);
            max-height: calc(100vh - 60px);
            border: 0;
            border-radius: 0;
            overflow: hidden;

            .-dropdownOptions {
                max-height: calc(100vh - 60px);
            }
        }
    }

    &.-mainSearch {
        width: 100%;
        padding-bottom: 0;
    }
}

.-dropdownWrapper {
    position: relative;
    margin-left: 10px;

    @include media-up(l) {
        margin-right: 10px;
    }

    & > .a-iconBtn {
        svg circle {
            fill: $red;
        }
    }

    .countBadge {
        position: absolute;
        width: 12px;
        height: 12px;
        top: 3px;
        right: 3px;
        background-color: $red;
        border-radius: 100%;
        font-size: 8px;
        line-height: 10px;
        color: $white;
        font-weight: 600;
        cursor: pointer;

        .plus {
            font-size: 6px;
            line-height: 8px;
        }
    }
}

.m-header__main {
    .m-dropdown {
        width: 500px;
        right: 0;

        &.-mainSearch {
            width: 100%;
        }
    }
}
