.m-profilePictureUploader {
    @include media-down(l) {
        height: 150px;
    }

    &--image,
    &--placeholder {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        min-width: 150px;
        height: 0;
        padding-top: 150px;
        border-radius: 50%;

        .-status {
            z-index: 2;
            position: absolute;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center bottom -1px;
            border: 0.1px solid $dark-blue-20;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-width: 100%;
            height: 100%;
            top: 0;
            border-radius: 100%;

            &.-available {
                background-image: url('../../../images/available.png');
            }

            &.-unavailable {
                background-image: url('../../../images/unavailable.png');
            }

            &.-employed {
                background-image: url('../../../images/employed.png');
            }

            &.-freelancer {
                background-image: url('../../../images/freelancer.png');
            }
        }

        @include media-down(l) {
            height: 150px;
            width: 150px;
            min-width: 150px;
            padding-top: 0;
        }

        .-placeholderImage {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &--placeholder {
        z-index: 1;
        background-color: $dark-blue-20;
    }

    &--image {
        z-index: 2;
        position: absolute;
        top: 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        outline: 1px solid $dark-blue-05;
        transition: 0.3s ease-in-out;

        //prevent image with transparent background to interact with other elements
        &.-hasImage {
            background-color: $white;
        }
    }

    .-profileImageBox {
        position: relative;

        .m-profilePictureUploader--image {
            cursor: pointer;
            transition: 0.3s ease-in-out;
        }

        .-tooltip {
            min-width: unset;
            top: unset;
            bottom: 0;
            right: 30px;
        }

        &:hover {
            .m-profilePictureUploader--image {
                background-color: $dark-blue-20;
            }

            .-tooltip {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .-imageButton {
        position: absolute;
        top: unset;
        bottom: 5px;
        right: 5px;
        background: none;
        outline: none;
        border: none;
        padding: 0;
        transition: 0.3s ease-in-out;
        z-index: 3;

        .a-iconBtn {
            position: relative;
            cursor: pointer;
        }

        &.-hidden {
            display: none;
        }

        .-tooltip {
            min-width: unset;
            white-space: nowrap;
            top: unset;
            bottom: 10px;
            right: -20px;
        }

        &:hover {
            .-tooltip {
                visibility: visible;
                opacity: 1;
            }
        }

        .-plus {
            background-color: $light-blue;
            width: 32px;
            height: 32px;
            min-width: 32px;
            min-height: 32px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    &.-signUp {
        align-items: center;
    }

    .-positions,
    .-location {
        gap: 5px;
        flex-wrap: wrap;
    }

    @include media-down(m) {
        .m-avatar__popup {
            transform: translate(50%, 100%);
            right: 50%;
        }
    }
}
