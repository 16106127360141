.m-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .pagination-selector {
        display: flex;
        align-items: center;
        color: $dark-blue-40;
        font-size: 14px;

        select {
            height: 40px;
            margin: 0 8px;
            border-radius: 3px;
            padding: 2px 10px;
            background-color: $white;
            border-color: $dark-blue-05;

            @include media-down(s) {
                padding: 2px 4px;
            }

            &:focus-visible {
                outline: none;
            }
        }

        .-entities {
            @include media-down(l) {
                display: none;
            }
        }
    }

    .pagination {
        left: 0;
        display: inline-flex;
        align-items: center;
        background-color: $white;

        .item {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 40px;
            height: 40px;
            border-radius: 4px;
            padding: 0 5px;
            text-decoration: none;
            user-select: none;
            cursor: pointer;
            color: $dark-blue;
            transition: 0.3s ease-in-out;

            &:not(.-arrow) {
                border: 1px solid $dark-blue-05;
                margin-right: 10px;
            }

            &.-arrow {
                width: 20px;
                min-width: 20px;
                margin-right: 10px;
                padding: 0;

                svg path {
                    stroke: $dark-blue;
                }

                &.-flipped {
                    transform: scaleX(-1);
                }
            }

            &:hover {
                background-color: $light-blue-03;
                color: $light-blue;
            }

            &.active {
                border-color: transparent;
                background-color: $light-blue-05;
                color: $light-blue;
            }

            &.disabled {
                pointer-events: none;
                color: $dark-blue-40;

                svg path {
                    stroke: $dark-blue-40;
                }
            }
        }

        .customPaginationPager {
            margin-right: 8px;
        }
    }

    &.-differentOnSmallScreens {
        @include media-down(m) {
            .-arrow {
                background-color: $light-blue-05;
                width: 40px !important;
                height: 40px;

                svg {
                    transform: scale(1.5);
                }

                &:first-of-type {
                    display: none;
                }

                &.-flipped {
                    &:last-of-type {
                        display: none;
                    }
                }
            }

            .item {
                &.-number {
                    display: none;
                }
            }
        }
    }
}
