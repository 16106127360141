.a-alert {
    z-index: 99;
    position: fixed;
    top: 30px;
    right: 30px;
    width: 100%;
    max-width: 80vw;
    border-radius: 10px;
    padding: 10px 20px;
    opacity: 1;
    cursor: pointer;

    @include media-up(l) {
        min-width: 500px;
    }

    @include media-up(m) {
        max-width: 600px;
    }

    @include media-down(s) {
        min-width: unset;
        width: 80vw;
    }

    &__left {
        width: calc(100% - 45px);

        .-text {
            width: calc(100% - 45px);

            span {
                word-wrap: break-word;
                word-break: break-all;
            }
        }
    }

    &.-center {
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
    }
}
