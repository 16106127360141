.m-form {
    &__title {
        margin-bottom: 20px;
    }

    &__text {
        display: flex;
        flex-direction: column;
    }

    &__link {
        display: block;
    }

    &.-login,
    &.-register {
        .a-btn {
            margin-bottom: 10px;
            width: 100%;
            height: 50px;
        }
    }

    .-disabled {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
    }

    .-skipLink {
        display: flex;
        justify-content: center;
    }

    &.-register {
        & > .a-btn,
        & > .invitedByOrganization,
        & > .a-separator,
        .-descriptionText {
            @include media-up(m) {
                max-width: 400px;
            }
        }

        .m-radioGroupContainer {
            text-align: center;
        }

        .m-inputGroup.-icon svg {
            top: 32px;
        }

        .a-btn.-quaternary {
            position: relative;

            svg {
                position: absolute;
                left: 20px;
            }
        }

        .-soon {
            margin-top: 20px;
        }

        .-passwordDescription {
            text-align: left;
            margin-top: -15px;
            margin-bottom: 20px;
        }

        .invitedByOrganization {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            background-color: $dark-blue-02;
            border: 1px solid $dark-blue-20;
            border-radius: 5px;
            padding: 20px;

            .a-avatarIcon__wrapper {
                margin-right: 15px;
            }
        }
    }

    &.-password {
        text-align: left;

        .-description {
            margin-top: -15px;
            margin-bottom: 20px;
        }

        .m-inputGroup {
            margin-bottom: 20px;
        }

        .a-btn {
            @include media-up(m) {
                max-width: 400px;
            }
        }
    }

    .-forgotPassword {
        display: flex;
        justify-content: flex-end;
    }
}

.-inputWithIcon {
    .m-inputGroup.-icon svg {
        top: 50px;
    }
}
